//projectList-css
.project-list{
  padding: 46px 77px 0;
  position: relative;
  .project-box{
    color: #9398A4;
    .ant-card-body {
      padding: 0;
    }
    .project-box-title{
      display: flex;
      flex-direction: row;
      .project-box-title-tip{
        margin-left: 60px;
        color: #9398A4;
      }
    }
    .project-box-item{
      .project-box-item-info{
        font-size: 14px;
        color: #9398A4;
      }
    }
  }
  .normal-tabs{
    .ant-tabs-nav{
      width: 60%;
      margin-left: 0;
    }
  }
  .ant-tabs-tab-btn{
    padding: 4px 20px;
  }
  .ant-tabs-tab-active{
    border: 1px solid #FFAB3B;
    border-radius: 23px;
  }
  .ant-tabs-ink-bar{
    border: none;
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom:none;
  }
  .search-box{
    width: 35%;
    height: 40px;
    position: absolute;
    right: 77px;
    top: 46px;
    display: flex;
    justify-content: space-between;
    .search-cate{
      display: flex;
      align-items: center;
      color: #9398A4;
    }
    .search-box-input{
      width: 85%;
      display: flex;
      justify-content: space-between;
      .ant-input-affix-wrapper {
        border-radius: 25px;
      }
      .title-input{
        width: 85%;
        font-size: 12px;
      }
      .add-box{
        display: flex;
        text-align: center;
        align-items: center;
        align-content: center;
        align-self: center;
        color: #FFAB3B;
      }
    }
  }
}


//projectDetail-css
.project-detail{
  padding: 60px 105px 20px;
  .project-item-title{
    display: flex;
    justify-content: space-between;
    .project-item-title-name{
      font-size: 25px;
      display: flex;
      align-items: center;
      .ant-tag-orange {
        color: #FFAB3B;
        background: #FFF;
        border-color: #FFAB3B;
        border-radius: 25px;
        padding: 0 15px;
        line-height: 25px;
        margin-left: 10px;
      }
    }
    .project-item-title-right{
      display: flex;
      align-items: center;
      .icon-style{
        font-size: 20px;
        color: #9398A4;
      }
      .divider-title{
        height: 20px;
        margin: 0 10px;
      }
    }
  }
  .horizontal-divider-width{
    width: 100%;
    height: 1px;
    color: #BFC1C5;
  }
  .normal-tabs{
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
      position: relative;
      display: inline-block;
      display: flex;
      flex: auto;
      align-self: stretch;
      overflow: hidden;
      white-space: nowrap;
      transform: translate(0);
      background-color: #F8F8F4;
      justify-content: center;
    }
    .ant-tabs-tab {
      padding: 4px 20px !important;
      font-size: 16px !important;
      color: #9398A4;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 10px 0 10px 5px !important;
    }
  }
}

//ProjectDetailWraparound-css
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: calc(15% - 12px);
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-tail, .ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 20%;
}
.ant-timeline-item-content {
  margin: 0 0 0 23%;
}
.overView-box{
  text-align: left;
  .overView-box-left{
    padding-left: 20px;
    .overView-box-left-sort-box{
      text-align: right;
      margin: 10px 10%;
    }
    .timeline-item{
      display: flex;
      flex-direction: row;
      width: 100%;
      font-size: 16px;
      .timeline-item-body-header{
        padding-top: 10px;
        margin-left: 10px;
      }
      .timeline-item-body{
        width: 100%;
        .timeline-item-body-box{
          background-color: rgba(228, 228, 228, 0.41);
          width: 90%;
          padding:10px 0 10px 10px;
          margin-top: 10px;
          margin-left: 10px;
          border-radius: 4px;
          .timeline-item-body-box-time{
            color:#9398A4;
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }
    .loading-style{
      width: 100%;
      text-align: center;
    }
  }
  .right-item-boxnew{
    min-height: 60px;
    margin-bottom: 0px;
  }
  .overView-box-right{
    .right-item-box{
      min-height: 120px;
      margin-bottom: 20px;
      .right-item-box-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 16px;
        color: rgba(0, 0, 0, 1);
        align-items: center;
        .edit-style{
          color:#BFC1C5;
          font-size: 14px;
        }
      }
      .ant-divider-horizontal {
        margin: 5px 0 10px;
      }
      .ant-divider{
        border-top: 2px solid rgba(0, 0, 0, 0.06);
      }
      .right-item-box-body{
        .right-item-box-team{
          font-size: 16px;
          color: rgba(0, 0, 0, 1);
        }
        .right-item-box-body-row{
          margin: 10px 0;
          .right-item-box-body-title{
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #9398A4;
            .right-item-box-body-title-bold{
              color: black;
              margin-left: 6px;
            }
          }
        }
        .right-item-box-body-row-space{
          margin: 10px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .right-item-box-body-row-space-left{
            display: flex;
            flex-direction: row;
            .right-item-box-body-title{
              display: flex;
              flex-direction: row;
              align-items: center;
              color: #9398A4;
              .right-item-box-body-title-bold{
                color: black;
                margin-left: 6px;
              }
            }
          }
        }
      }
    }
  }
}

//新建项目
.project-creat{
  padding: 36px 40px;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    cursor: text;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .content-model-start{
    .normal-tabs{
      .ant-tabs-nav {
        color: red;
        min-width: 30%;
        max-width: 30%;
        margin-left: 0;
      }
    }
    .ant-modal-content{
      border-radius: 10px;
    }
    .ant-modal-footer {
      padding: 0;
      text-align: right;
      background: transparent;
      border-top: 0 solid #f0f0f0;
      border-radius: 0 0 2px 2px;
    }
    .ant-modal-centered .ant-modal {
      top: 0;
    }
    .content-model-title{
      .content-model-title-name{
        text-align: center;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .content-model-body{
      .ant-tabs-tab + .ant-tabs-tab{
        margin: 0 0 0 0;
      }
      .ant-tabs-tab {
        padding: 10px 0;
      }
      .ant-tabs-nav {
        width:100%;
        min-width: 30% !important;
        max-width: 30% !important;
        margin-left: 0;
      }
      .select-box{

        .select-item{
          padding: 4px 20px;
          border-bottom: 1px solid #d9d9d9;
          margin: 10px 0 10px;
          text-align: center;
          background-color: #F0F3F8;
        }
      }
      .content-model-body-form{
        .ant-form-item-explain.ant-form-item-explain-error {
          margin-left: 20px;
        }
        .content-model-body-form-input{
          margin-left: 20px;
        }
        .ant-form-item-label > label {
          font-size: 16px;
          font-weight: bold;
        }
        .content-model-body-form-btn-box{
          display: flex;
          justify-content: space-around;
          .content-model-body-form-btn-style{
            border-radius: 25px;
            padding: 0 40px;
          }
        }
      }

    }
  }
  .content-model-template{
    .normal-tabs{
      .ant-tabs-nav {
        color: red;
        min-width: 30%;
        max-width: 30%;
        margin-left: 0;
      }
    }
    .ant-modal-content{
      border-radius: 10px;
    }
    .ant-modal-footer {
      padding: 0;
      text-align: right;
      background: transparent;
      border-top: 0 solid #f0f0f0;
      border-radius: 0 0 2px 2px;
    }
    .ant-modal-centered .ant-modal {
      top: 0;
    }
    .content-model-title{
      .content-model-title-name{
        text-align: center;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .template-btn-box{
      width: 100%;
      text-align: center;
      margin-top: 20px;
      .template-btn{
        padding: 5px 10px;
        border: none;
        box-shadow: 3px 2px 6px #00000029;
      }
    }
    .content-model-body{
      text-align: center;
      .ant-tabs-tab + .ant-tabs-tab{
        margin: 0 0 0 0;
      }
      .ant-tabs-tab {
        padding: 10px 0;
      }
      .ant-tabs-nav {
        width:100%;
        min-width: 30% !important;
        max-width: 30% !important;
        margin-left: 0;
      }
      .template-content {
        background-color: #ffffff;
        border-radius: 3px;
        transition: top .25s;
        position: relative;
        top: 0;
        box-shadow: 0px 1px 4px 0px #00000029;
        width: 240px;
        padding-bottom: 5px;
        margin: 0 10px 10px;
        display: inline-block;
        .template-content-show{
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 125px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .template-content-bg{
          cursor: pointer;
          display: flex;
          justify-content: center;
          height: 120px;
          border: none;
        }
      }

      .template-content:hover{
        box-shadow: 0px 2px 6px 0px #00000029;
        .template-content-bg{
          filter:blur(2px);
        }
        .template-content-show{
          z-index: 999;
          opacity: 1;
          background-color: rgba(255, 255, 255, 0.6);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 125px;
          display: flex;
          justify-content: center;
          align-items: center;
          .template-content-show-btn{
            padding: 5px 20px;
            border-radius: 20px;
            background-color: #FFAB3B;
            color: white;
            border: none;
            box-shadow: 0px 1px 4px 0px #00000029;
          }
        }
      }

      .template-content-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: cover;
        width: 100%;
        height: 100%;
      }
      .template-content-item-footer{
        padding: 0 3px 0 5px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        object-fit: contain;
        color: #cdc9c5;
        font-size: 12px;
      }
    }
  }
  .ant-form-item-control-input-content .input-item{
    border-bottom: 1px solid #d9d9d9;
  }
  .first-step-area{
    //width: 100%;
    .first-step-radio{
      display: flex;
      margin: 0 0 20px 60px;
    }
    .ant-form-item-control-input-content{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .return-btn{
      float: left;
      height: 33px;
      width: 114px;
      background: #FFFFFF 0% 0% ;
      border: 1px solid #9398A4;
      border-radius: 17px;
  };
  .creat-area{
    padding: 50px 0 0 20px;
    width: 100%;
    .ant-steps .ant-steps-vertical {
      display: flex;
      flex-direction: column;
    }
    .creat-steps{
      display: flex;
      //align-items: center;
      .ant-steps-item-container {
        margin: 0 0 0 18% ;
        height: 160px;
      }
      .ant-steps-item-tail{
        margin: 0 18%;
      }
      .ant-steps-item-title {
        font-size: 14px;
      }
      .item-import{
        padding-left: 5px;
        font-size: 20px;
        color: red;
      }
    }
    .project-divider{
      .vertical-divider-title{
        height: 100%;
        width: 2px;
      }
    }
    .create-content{
      width: 100%;
      float: left;
      .select-item{
        border-bottom: 1px solid #d9d9d9;
      }
      .dynamic-delete-button {
        position: relative;
        margin-left: 10px;
        color: #999;
        font-size: 24px;
        cursor: pointer;
        transition: all 0.3s;
      }
      .dynamic-delete-button:hover {
        color: #777;
      }
      .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }
      .steps-content{
        width: 100%;
      }
      .steps-action{
        //position: absolute;
        width: 100%;
        bottom: 0;
        //float: left;
      }
    }
  }
  .content-model{
    .ant-modal-centered .ant-modal {
      top: 100px;
    }
    .content-model-title{
      .content-model-title-name{
        text-align: center;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: bolder;
      }
    }
    .content-model-body{
      .content-model-body-form{
        .ant-form-item{
          width: 100%;
        }
        .model-title{
          padding-bottom: 5px;
          border-bottom: 3px solid #FFAB3B;
          width: fit-content;
          font-size: 16px;
          font-weight: bolder;
        }
        .model-audit{
          width: 20%;
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          font-size: 16px;
          font-weight: bolder;
        }
        .model-textArea{
          margin-bottom: 20px;
        }
        .model-row{
          .flex-col{
            display: flex;
            flex-direction: row;
            align-items: center;
            .ant-radio-group{
              display: flex;
            }
          }
          .project-input-title{
            width: 30%;
          }
          .project-input-title-row{
            width: 100%;
          }
          .ant-input-number{
            border-top: none;
            border-left: none;
            border-right: none;
            width: 100%;
          }
          .ant-input-number-focused{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-btn{
            width: 100%;
            border-top: none;
            border-left: none;
            border-right: none;
            box-shadow: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .ant-btn, .ant-btn:active, .ant-btn:focus, .ant-btn:active{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-btn:hover, .ant-btn:focus, .ant-btn:active{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-input {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          .ant-input:focus{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-picker {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          .ant-picker-focused{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
        }

      }
    }
  }
  .ant-divider-horizontal {
    margin: 0 0 10px;
  }
  .table-box{
    margin: 10px 140px 10px 70px;
  }
  .add-box{
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 40px;
  }
}


//财务
.finance-box{
  .ant-input-affix-wrapper {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    box-shadow: none !important;
    border-radius:0 !important;
  }
  .model-textArea{
    margin-bottom: 20px;
  }
  .finance-box-title{
    margin-top: 10px;
    color: #9398A4;
    font-size: 15px;
    .title-name{
      color: black;
      border-bottom: 3px solid #FFAB3B;
      text-align: center;
    }
    .title-left{
      text-align: left;
      padding-left: 20px;
    }
    .title-right{
      text-align: right;
    }
  }
  .pro-box{
    .ant-card-body {
      padding: 0;
    }
    .confirmBtn{
      background-color: #FC9B1A;
      color: white;
      border: none;
    }
  }
  .ant-divider-horizontal {
    margin: 0 0 10px;
  }

  .content-model{
    .ant-modal-centered .ant-modal {
      top: 100px;
    }
    .ant-form-item .ant-select, .ant-form-item .ant-cascader-picker{
      border-bottom: 1px solid #d9d9d9;
    }
    .content-model-title{
      .content-model-title-name{
        text-align: center;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: bolder;
      }
    }
    .content-model-body{
      .content-model-body-form{
        .ant-form-item{
          width: 100%;
        }
        .model-title{
          padding-bottom: 5px;
          border-bottom: 3px solid #FFAB3B;
          width: fit-content;
          font-size: 16px;
          font-weight: bolder;
        }
        .model-audit{
          width: 20%;
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          font-size: 16px;
          font-weight: bolder;
        }
        .model-textArea{
          margin-bottom: 20px;
        }
        .model-row{
          .flex-col{
            display: flex;
            flex-direction: row;
            align-items: center;
            .ant-radio-group{
              display: flex;
            }
          }
          .project-input-title{
            width: 30%;
          }
          .project-input-title-row{
            width: 100%;
          }
          .ant-input-number{
            border-top: none;
            border-left: none;
            border-right: none;
            width: 100%;
          }
          .ant-input-number-focused{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-btn{
            width: 100%;
            border-top: none;
            border-left: none;
            border-right: none;
            box-shadow: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .ant-btn, .ant-btn:active, .ant-btn:focus, .ant-btn:active{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-btn:hover, .ant-btn:focus, .ant-btn:active{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-input {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          .ant-input:focus{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-picker {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          .ant-picker-focused{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-form-item-control-input-content{
            display: flex;
          }
        }

      }
    }
  }
}

//用章
.seal-box{
  .pro-box{
    .ant-input-affix-wrapper{
      width: 260px;
    }
    .ant-card-body {
      padding: 0;
    }
    .ant-pro-table-list-toolbar-container{
      padding:0px 0px 16px;
    }
  }
  .ant-divider-horizontal {
    margin: 0 0 10px;
  }
  .content-model{
    .content-model-title{
      .content-model-title-name{
        text-align: center;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: bolder;
      }
    }
    .content-model-body{
      .content-model-body-form{
        .ant-form-item{
          width: 100%;
        }
        .model-title{
          padding-bottom: 5px;
          border-bottom: 3px solid #FFAB3B;
          width: fit-content;
          font-size: 16px;
          font-weight: bolder;
        }
        .model-audit{
          width: 20%;
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          font-size: 16px;
          font-weight: bolder;
        }
        .model-textArea{
          margin-bottom: 20px;
        }
        .model-row{
          .flex-col{
            display: flex;
            flex-direction: row;
            align-items: center;
            .ant-radio-group{
              display: flex;
            }
          }
          .project-input-title{
            width: 30%;
          }
          .project-input-title-row{
            width: 100%;
          }
          .ant-input-number{
            border-top: none;
            border-left: none;
            border-right: none;
            width: 100%;
          }
          .ant-input-number-focused{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-btn{
            width: 100%;
            border-top: none;
            border-left: none;
            border-right: none;
            box-shadow: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .ant-btn, .ant-btn:active, .ant-btn:focus, .ant-btn:active{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-btn:hover, .ant-btn:focus, .ant-btn:active{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-input {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          .ant-input:focus{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-picker {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          .ant-picker-focused{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
        }

      }
    }
  }
  .content-model-task{
    .task-modal{
      padding: 44px 55px;
      .add-assign-modal{
        .add-assign-title{
          padding-left: 5%;
          font-size: 24px;
          color: #FC9B1A;
        }
        .assign-title{
          padding: 3% 10% 0 10%;
          .input-title{
            padding-right: 2%;
            font-size: 30px;
            align-items: center;
            display: flex;
            color: #FF4848;
            .input-title-text{
              font-size: 18px;
              color: #000000;
              width: 100%;
            }
          }
          .input-block{
            .input-box{
              width: 100%;
              padding-left: 10%;
              height: 50px;
              border-radius: 31px;
              font-size: 16px;
            }
          }
        }
        .title-horizontal {
          width: 100%;
          height: 10px;
          .horizontal-divider-modal-title {
            width: 95%;
            height: 1px;
            color: #9398A4;
          }
          .ant-divider-horizontal {
            display: flex;
            clear: both;
            min-width: 10%;
            margin: 20px auto 0px;
          }
        }
        .add-form {
          //padding: 5% 0 2% 0;
          .ant-form-item{
            padding-bottom: 16px;
          }
          .ant-input{
            border: 1px solid #9398A4;
            border-radius: 31px;
            height: 32px;
          }
          .ant-select-selector{
            border: 1px solid #9398A4;
            border-radius: 31px;
            height: 32px;
          }
          .ant-picker {
            border: 1px solid #9398A4;
            border-radius: 31px;
            height: 32px;
          }
          .add-customer-button{
            text-align: center;
            align-items: center;
            //align-content: center;
            margin-top: 8%;
            width: 100%;
            .customer-button-cancel{
              //margin-right: 5%;
              font-size: 18px;
              border-radius: 20px;
              width: 20%;
              height: 36px;
              color: #9398A4;
            }
            .customer-button-ok{
              margin-left: 5%;
              font-size: 18px;
              border-radius: 20px;
              width: 20%;
              height: 36px;
            }
          }
          .anticon-plus {
            color: #9398A4;
            //font-size: 20px;
          }
          .department-menu-divider{
            .vertical-divider-title{
              height: 100%;
              width: 2px;
            }
          }
          .detail-area{
            border: 1px solid #9398A4;
            border-radius: 14px;
            height: 200px;
          }
          .ant-form-item-control-input-content{
            width: 100%;
          }
          .check-box-group{
            width: 100%;
            padding-top: 10px;
            font-size: 20px;
            .check-box-item{
              width: 100%;
              justify-content: space-between;
              align-items: center;
              .ant-btn-icon-only{
                color: red;
              }
            }


          }
          .total-time{
            color: red;
            font-size: 18px;
          }
          .start-button{
            //margin-top: 10px;
            border-radius: 35px;
            float: right;
          }
          [data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
          .site-collapse-custom-collapse .site-collapse-custom-panel {
            margin-bottom: 24px;
            overflow: hidden;
            background: #f7f7f7;
            border-radius: 4px;
          }
          .time-item{
            border-top: #9398A4 1px;
            .time-item-row{
              width: 100%;
              justify-content: space-between;
              align-items: center;
            }
            .item-divider{
              margin: 10px 0;

            }
          }
          .ant-collapse-header .ant-collapse-extra {
            float: right;
            margin-top: -30px;
          }
        }



      }
    }
  }
}


//弹窗
.content-model{
  .ant-modal-content{
    border-radius: 10px;
  }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-footer {
    padding: 0;
    text-align: right;
    background: transparent;
    border-top: 0 solid #f0f0f0;
    border-radius: 0 0 2px 2px;
  }
  .ant-modal-centered .ant-modal {
    top: 0;
  }
  .content-model-title{
    .content-model-title-name{
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .content-model-body{
    .content-model-body-form{
      .ant-form-item-explain.ant-form-item-explain-error {
        margin-left: 20px;
      }
      .content-model-body-form-input{
        margin-left: 20px;
      }
      .ant-form-item-label > label {
        font-size: 16px;
        font-weight: bold;
      }
      .content-model-body-form-btn-box{
        display: flex;
        justify-content: space-around;
        .content-model-body-form-btn-style{
          border-radius: 25px;
          padding: 0 40px;
        }
      }
    }
  }
}

.content-model-edit{
  .ant-modal-footer {
    border-top: none;
  }
  .ant-modal-content{
    border-radius: 10px;
  }
  .ant-modal-centered .ant-modal{
    top: 0px;
  }
  .content-model-title-edit{
    .content-model-title-name-edit{
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .content-model-body-edit{
    .content-model-body-form-edit{
      .project-input-title-edit{
        margin-left: 17%;
        margin-bottom: 5px;
      }
      .select-item{
        border: 1px solid #d9d9d9;
      }
      .content-model-body-form-btn-box-edit{
        display: flex;
        justify-content: space-around;
        .content-model-body-form-btn-style-edit{
          border-radius: 25px;
          padding: 0 40px;
        }
      }
    }
  }

}


.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
  border-top: none;
  border-left: none;
  border-right: none;
}
.new-box{
  .overView-box-right{
    // display: block;
    // margin: 0 auto;
    flex: 0 0 100%;
    max-width: 100%;
}
}
@primary-color: #FC9B1A;