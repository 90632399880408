body{
  background-color: #F0F3F8 !important;
}
.header{
  .col-img{
    display: flex;
    height: 64px;
    text-align: center;
    align-items: center;
  }
  .col-title{
    display: flex;
    height: 64px;
    justify-content: end;
    font-size: 28px;
    color: black;
  }
  .col-divider{
    height: 64px;
    text-align: center;
    .divider-title{
      height: 64px;
      box-shadow: 2px 3px 3px #00000029;
      color: #CCCCCC;
    }
  }
  .col-name{
    height: 64px;
    display: flex;
    flex-direction: column;
    .title-name{
      color: black;
      font-size: 20px;
      height: 32px;
      line-height: 32px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      .user-name-title{
        font-size: 25px;
        margin-left: 11px;
        line-height: 32px;
        .user-name{
          color:#FFB85A;
        }
      }
    }
    .date{
      height: 32px;
      line-height: 32px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
  }
  .col-title{
    display: flex;
    height: 64px;
    text-align: center;
    align-items: center;
    .title-input{
      padding-left: 20px;
    }
    .ant-input-prefix {
      margin-right: 10px;
    }
    .ant-input-suffix {
      margin-left: 10px;
      margin-right: 10px;
    }
    a{
      color: #9398A4;
    }
  }
  .col-header{
    text-align: center;
  }

  .ant-input-affix-wrapper {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 25px;
  }
}

.content{
  padding: 0 9%;
  .tab-list{
    height: 64px;
    text-align: center;
    align-items: center;
    justify-content:space-around;
    .ant-tabs-content-holder {
      padding-bottom: 50px;
    }
    .ant-tabs-centered:first-child{
     padding:0 6px 3px
    }
    .ant-tabs-tab {
      padding:4px 0;
      font-size: 14px;
      color: #9398A4;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #FFAB3B;
      text-shadow: 0 0 0.25px currentColor;
    }
    .ant-tabs-tab.ant-tabs-tab-active{
      color: #FFAB3B;
    }
    .ant-tabs-tab:hover {
      color: #FFAB3B;
    }
    .ant-tabs-ink-bar {
      background: #FFAB3B;
      border-radius: 10px;
      border: 2px solid #FFAB3B
    }
    .tab-box{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      font-size: 14px;
      padding: 0 20%;
      .tab-img{
        display: block;
        width: 100%;
      }
    }
    .ant-tabs-tab + .ant-tabs-tab{
      margin: 0 0 0 5px;
    }
    .ant-tabs-nav{
      min-width: 30%;
      max-width: 35%;
      margin-left: 58%;
    }
    .ant-tabs-nav .ant-tabs-nav-wrap::before{
      display: none;
    }
    .ant-tabs-nav .ant-tabs-nav-wrap::after{
      display: none;
    }
    .normal-tabs{
      .ant-tabs-tab + .ant-tabs-tab{
        margin: 0 0 0 5px;
      }
      .ant-tabs-nav{
        width: 100%;
        min-width: 0 !important;
        max-width: 100% !important;
        margin-left: 0;
      }
      .ant-tabs-nav .ant-tabs-nav-wrap::before{
        display: block;
      }
      .ant-tabs-nav .ant-tabs-nav-wrap::after{
        display: block;
      }
      .ant-tabs-tab {
        padding:4px 0;
        font-size: 14px;
        color: #9398A4;
      }
      .ant-tabs-content-holder {
        padding-bottom: 10px;
      }
    }
  }
  .normal-tabs{
    .ant-tabs-nav{
      min-width: 30%;
      max-width: 30%;
      margin-left: 58%;
    }
  }
}

.fotter{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  text-align: center;
  padding: 10px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
  color: #6D7078;
  font-weight: bold;

}

.ant-layout-header {
  height: 112px;
  color: #9398A4;
  line-height: 64px;
  background: #F0F3F8;
  padding: 40px 9% 0;
}



.ant-layout-footer {
  padding: 0px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}




@primary-color: #FC9B1A;