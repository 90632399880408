.conflict-box{
  padding: 46px 10px;
  height: 100%;
  .ant-input-affix-wrapper {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 25px;
  }
  .col-title{
    display: flex;
    height: 64px;
    text-align: center;
    align-items: center;
    .title-input{
      padding-left: 20px;
    }
    .ant-input-prefix {
      margin-right: 10px;
    }
    .ant-input-suffix {
      margin-left: 10px;
      margin-right: 10px;
    }
    a{
      color: #9398A4;
    }
  }
  .model-textArea{
    margin-bottom: 20px;
  }
  .finance-box-title{
    margin-top: 10px;
    color: #9398A4;
    font-size: 15px;
    .title-name{
      color: black;
      border-bottom: 3px solid #FFAB3B;
      text-align: center;
    }
    .title-left{
      text-align: left;
      padding-left: 20px;
    }
    .title-right{
      text-align: right;
    }
  }
  .pro-box{
    .ant-card-body {
      padding: 0;
    }
  }
  .ant-divider-horizontal {
    margin: 0 0 10px;
  }

  .content-model{
    .ant-modal-centered .ant-modal {
      top: 100px;
    }
    .content-model-title{
      .content-model-title-name{
        text-align: center;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: bolder;
      }
    }
    .content-model-body{
      .content-model-body-form{
        .ant-form-item{
          width: 100%;
        }
        .model-title{
          padding-bottom: 5px;
          border-bottom: 3px solid #FFAB3B;
          width: fit-content;
          font-size: 16px;
          font-weight: bolder;
        }
        .model-audit{
          width: 20%;
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          font-size: 16px;
          font-weight: bolder;
        }
        .model-textArea{
          margin-bottom: 20px;
        }
        .model-row{
          .flex-col{
            display: flex;
            flex-direction: row;
            align-items: center;
            .ant-radio-group{
              display: flex;
            }
          }
          .project-input-title{
            width: 30%;
          }
          .project-input-title-row{
            width: 100%;
          }
          .ant-input-number{
            border-top: none;
            border-left: none;
            border-right: none;
            width: 100%;
          }
          .ant-input-number-focused{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-btn{
            width: 100%;
            border-top: none;
            border-left: none;
            border-right: none;
            box-shadow: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .ant-btn, .ant-btn:active, .ant-btn:focus, .ant-btn:active{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-btn:hover, .ant-btn:focus, .ant-btn:active{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-input {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          .ant-input:focus{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-picker {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          .ant-picker-focused{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
        }

      }
    }
  }
}
@primary-color: #FC9B1A;