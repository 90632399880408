
.user-management{
  padding: 46px 77px 0;
  position: relative;
  .user-management-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .user-management-title-left{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0 20px 0;
      font-size: 22px;
      color: black;
    }
  }
  .divider-title{
    height: 1px;
    width: 100%;
    color: #BFC1C5;
  }
  .ant-card-body{
    margin-bottom: 20px;
  }
  .tableListForm {
    text-align: left;
    margin-top: 10px;
    .ant-row .ant-form-item{
      margin-bottom: 20px;
    }
    .ant-btn{
      margin-bottom: 20px;
    }
  }
}

.project-box{
  color: #9398A4;
  .ant-card-body {
    padding: 0;
  }
  .project-box-title{
    display: flex;
    flex-direction: row;
    .project-box-title-tip{
      margin-left: 60px;
      color: #9398A4;
    }
  }
  .project-box-item{
    .project-box-item-info{
      font-size: 14px;
      color: #9398A4;
    }
  }
}

@primary-color: #FC9B1A;