.human-resource-page {
  padding: 46px 77px 0;
  position: relative;
  .content-body{
    .ant-tabs-nav {
      min-width: 30%;
      max-width: 31%;
      margin-left: 0 !important;
    }
  }
  .institution-firm-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .institution-firm-title-left{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0 20px 0;
      font-size: 22px;
      color: black;
    }
  }
  .content-body{
    margin-top: 20px;
    .ant-tabs-tab {
      padding: 4px 10px;
      font-size: 16px;
    }
    .content-body-number-span{
      font-size: 16px;
      color: #9398A4;
      .content-body-number{
        color: #FFAB3B;
      }
    }
    .project-box{
      .ant-card-body {
        padding: 0;
      }
      .ant-pro-table-list-toolbar-container {
        float: right;
        padding: 0 0 10px;
      }
    }
    .department-customer-search{
      width: 20%;
      display: flex;
      height: 32px;
      margin-left: 65%;
      text-align: center;
      align-items: center;
      .ant-input-prefix {
        margin-right: 10px;
      }
      .ant-input-affix-wrapper {
        box-shadow: 0px 0px 0px #00000029;
        border-radius: 25px;
      }
      a{
        color: #9398A4;
      }
    }
  }

}

.content-model{
  .ant-modal-footer {
    padding: 0;
    text-align: right;
    background: transparent;
    border-top: 0 solid #f0f0f0;
    border-radius: 0 0 2px 2px;
  }

  .content-model-body{
    display: block;
    .content-model-body-form{
      display: block;
      .ant-form-item-explain.ant-form-item-explain-error {
        margin-left: 20px;
      }
      .content-model-body-form-input{
        margin-left: 20px;
      }
      .ant-form-item-label > label {
        font-size: 16px;
        font-weight: bold;
      }
      .content-model-body-form-btn-box{
        margin-top: 3%;
        display: flex;
        justify-content: space-around;
        .content-model-body-form-btn-style{
          border-radius: 25px;
          padding: 0 40px;
        }
      }
    }
  }
}



@primary-color: #FC9B1A;