
.customer-all  {
  padding: 46px 10px;
  height: 100%;
  .customer-menu{
    width: 100%;
    padding-left: 20%;
    .menu-item-background {
      font-size: 16px;
      border-radius: 15px;
      .img-box {
        text-align: center;
        display: flex;
      }
      .text-grey{
        color: #9398A4;
      }
      .text-active{
        color: #FC9B1A;
      }
    }
    .horizontal-divider{
      width: 100%;
      height: 2px;
    }
  }
  .col-divider{
    .divider-title{
      width: 2px;
      height: 100%;
    }
  }
  .content-right{
    padding-right: 3%;
    .cuslist-box{
      .pro-box{
        .ant-input-affix-wrapper{
          width: 260px;
        }
        .ant-card-body {
          padding: 0;
        }
        .ant-pro-table-list-toolbar-container{
          padding:0px 0px 16px;
        }
      }
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #F0F3F8;
    border-radius: 30px;
    color: #FC9B1A;
  }
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0px solid #f0f0f0;
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 30px;
  }
  .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 53px;
    line-height: 53px;
  }

  .content-model{
    .add-customer-title{
      padding-right: 60%;
      font-size: 30px;
      color: #FC9B1A;
    }
    .title-horizontal{
      width: 100%;
      height: 10px;
      .horizontal-divider-modal-title {
        width: 90%;
        height: 1px;
        color: #9398A4;
      }
      .ant-divider-horizontal {
        display: flex;
        clear: both;
        min-width: 10%;
        margin: 20px auto 0px;
      }


    }
    .ant-modal-content{
      border-radius: 10px;
      .ant-modal-header {
        padding: 10px 24px;
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-radius: 2px 2px 0 0;
        border-bottom: 0 solid #f0f0f0;

        .ant-modal-title{
          text-align: center;
        }
      }
      .ant-modal-footer {
        padding: 10px 16px;
        text-align: right;
        background: transparent;
        border-top: 0 solid #f0f0f0;
        border-radius: 0 0 2px 2px;
      }
    }
    .customer-type-selected {
      text-align: center;
      .add-customer-form{
        padding: 3% 10%;
        .model-row{
          margin-bottom: 24px;
          .flex-col{
            display: flex;
            flex-direction: row;
            align-items: center;
            .ant-radio-group{
              display: flex;
            }
          }
          .project-input-title{
            width: 30%;
            text-align: left;
            font-weight: 600;
          }
          .project-input-title-row{
            width: 100%;
          }
          .ant-input-number{
            border-top: none;
            border-left: none;
            border-right: none;
            width: 100%;
          }
          .ant-input-number-focused{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-btn{
            width: 100%;
            border-top: none;
            border-left: none;
            border-right: none;
            box-shadow: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .ant-btn, .ant-btn:active, .ant-btn:focus, .ant-btn:active{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-btn:hover, .ant-btn:focus, .ant-btn:active{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-input {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          .ant-input:focus{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
          .ant-picker {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          .ant-picker-focused{
            border-top: none;
            border-left: none;
            border-right: none;
            border-right-width: 0 !important;
            box-shadow: none;
          }
        }
        .ant-form-item{
          margin: 0;
          width:100%;
        }
        .ant-input{
          border-top: none;
          border-left: none;
          border-right: none;
        }
        .ant-input:focus{
          border-top: none;
          border-left: none;
          border-right: none;
          border-right-width: 0 !important;
          box-shadow: none;
        }
        .text-input{
          font-size: 16px;
          .text-input-name{
            padding-left: 10px;
            color: #9398A4;
          }
          .horizontal-divider-modal-input-inline{
            color: #707070;
            width: 100%;
            height: 2px;
            margin: 2px;
          }
        }
        .text-input-items{
          width: 40%;
          .text-input-name{
            padding-left: 10px;
            font-size: 16px;
            color: #9398A4;
          }
          .horizontal-divider-modal-input-inline{
            color: #707070;
            width: 100%;
            height: 2px;
            margin: 2px;
          }
        }

      }
      .tab-box-left {
        padding: 0 10px;
        font-size: 16px;
        width: 100px;
        height: 30px;
        display: flex;
        text-align: center;
        align-items: center;
      }
      .tab-box-right {
        padding: 0 10px;
        font-size: 16px;
        width: 100px;
        height: 30px;
        display: flex;
        text-align: center;
        align-items: center;
      }
    }
    .content .tab-list .ant-tabs-nav {
      width: 80%;
      margin-left: 10%;
    }
    .content .tab-list .ant-tabs-content-holder {
      padding-bottom: 20px;
    }
    .add-customer-button{
      text-align: center;
      align-items: center;
      align-content: center;
      margin-top: 5%;
      .customer-button-cancel{
        margin-right: 5%;
        border-radius: 25px;
        width: 20%;
        color: #9398A4;
      }
      .customer-button-ok{
        margin-left: 5%;
        border-radius: 25px;
        width: 20%;
      }
    }
  }




}



@primary-color: #FC9B1A;