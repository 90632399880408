
.login{
  background: #F0F3F8;
  background-size: 100% 100%;
  margin: 0 auto;
  position: relative;
  display: table;
  .login-card {
    width: 100%;
    height: 100%;
    justify-content:center;//子元素水平居中
    align-items:center;//子元素垂直居中
    display:-webkit-flex;
    .card{
      background: white;
      width: 30%;
      position:fixed;
      right: 10%;
      z-index:2;
      box-shadow: 0px 3px 6px #03030333;
      border-radius: 10px;
      .logo_name{
        width: 54%;
        height: 9%;
        padding: 10% 0;
        margin: 0 auto;
        font-size: 26px;
      }
      .logo{
        width: 54%;
        height: 9%;
        padding: 12% 0 10% 0;
        margin: 0 auto;
      }
      .ant-tabs {
        max-width: 99%;
        //min-width: 60%;
        //width: 100%;
        height: 100%;
        .ant-tabs-nav-wrap{
          width: 100%;
          .ant-tabs-nav-list{
            width: 100%;
            .ant-tabs-tab {
              margin: 0 auto;
              display: block;
              //width: 50%;
              height: 12%;
              font-size: 15px;
              font-weight: bold;
              color: #9398A4;
            }
            .ant-tabs-ink-bar{
              height: 4px;
            }
          }

        }
        .ant-input-affix-wrapper > input.ant-input {
          font-size: 10px;
        }
        .login-form-input{
          font-size: 10px;
          height:50px;
          border-radius: 32px;
          .ant-input-prefix{
            padding: 0 10px;
          }
        }
        .login-form-input:hover {
          font-size: 10px;
          height:50px;
          border: 1px solid #FFAB3B;
          border-radius: 32px;
        }
        .ant-form-item{
          padding-top: 6% ;
        }
        .login-form-button{
          width: 100%;
          height:50px;
          border: 1px solid #FFAB3B;
          border-radius: 32px;
          background-color: #FFAB3B;
          opacity: 0.66;
          color: white;
          font-size:15px;
        }
        .login-form-button:hover{
          border: 1px solid #FFAB3B;
          background-color: #FFAB3B;
          opacity:1;
          font-size: 15px;
          color: white;
          height:50px;
        }
      }
      .ant-form-item-control-input {
        margin: 0 2px;
      }
      .ant-form-item-explain, .ant-form-item-extra {
        font-size: 10px;
        margin: 2px auto 2px 10px;
      }
      .scan-img{
        width: 30%;
        margin: 10px auto;
      }
      .scan-text{
        width: 80%;
        margin: 0 auto 20px auto;
        color: #75829B;
      }
      .code-btn{
        font-size: 10px;
        color: #9398A4;
      }

    }
   }
  .background-per{
    background: url(../../assets/background-per.png) no-repeat;
    background-size: 100% 100%;
    position:fixed;
    left: 20%;
    bottom: 20%;
  }
  .background-bottom {
    background: url(../../assets/background-bottom.png) no-repeat;
    background-size: 100% 100%;
    position:fixed;
    left:0;
    bottom:0;
    z-index:1;
  }
  }






@primary-color: #FC9B1A;