
.law-firm{
  padding: 46px 77px 0;
  position: relative;
  .law-firm-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .law-firm-title-left{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0 20px 0;
      font-size: 22px;
      color: black;
    }
  }
  .divider-title{
    height: 1px;
    width: 100%;
    color: #BFC1C5;
  }
}

.project-box{
  color: #9398A4;
  .ant-card-body {
    padding: 0;
  }
  .project-box-title{
    display: flex;
    flex-direction: row;
    .project-box-title-tip{
      margin-left: 60px;
      color: #9398A4;
    }
  }
  .project-box-item{
    .project-box-item-info{
      font-size: 14px;
      color: #9398A4;
    }
  }
}

.content-model{
  .ant-modal-footer {
    padding: 0;
    text-align: right;
    background: transparent;
    border-top: 0 solid #f0f0f0;
    border-radius: 0 0 2px 2px;
  }
  .content-model-body{
    .content-model-body-form{
      .ant-form-item-explain.ant-form-item-explain-error {
        margin-left: 20px;
      }
      .content-model-body-form-input{
        margin-left: 20px;
      }
      .ant-form-item-label > label {
        font-size: 16px;
        font-weight: bold;
      }
      .content-model-body-form-btn-box{
        display: flex;
        justify-content: space-around;
        .content-model-body-form-btn-style{
          border-radius: 25px;
          padding: 0 40px;
        }
      }
    }
  }
}
@primary-color: #FC9B1A;