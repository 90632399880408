.approval{
  color: #9398A4;
  font-size: 16px;
  .approval-card{
    .approval-title{
      text-align: left;
      display: flex;
      align-items: center;
      padding: 30px 0 6px 30px;
      font-size: 22px;
      color: black;
      .approval-text{
        align-self: center;
      }
      .approval-icon{
        width: 35px;
        height: 32.9px;
      }
    }
    .divider-title{
      height: 1px;
      width: 100%;
      color: #BFC1C5;
      margin: 0;
    }
    .approval-banner{
      .ant-tabs-tab {
        padding:4px 0;
        font-size: 18px;
        color: #9398A4;
      }
      .ant-tabs-nav{
        min-width: 30%;
        max-width: 30%;
        margin-left: 70%;
        margin-bottom: 0;
      }
      .ant-tabs-content-holder {
        padding-bottom: 0px;
      }

    }
    .approval-menu{
      font-size: 18px;
      padding: 20px 0 10px 10px;
      .all-approval-type{
        font-size: 18px;
      }
      .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
        border-right:none;
      }
      .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
        border-right:none;
      }
      .ant-menu-vertical .ant-menu-submenu, .ant-menu-vertical-left .ant-menu-submenu, .ant-menu-vertical-right .ant-menu-submenu, .ant-menu-inline .ant-menu-submenu {
        padding-bottom: 0.02px;
        font-size: 18px;
      }

      .ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
        color: #9398A4;
      }
      .ant-menu-sub.ant-menu-inline {
        border: 0;
        background: #FFFFFF;
        font-size: 16px;
      }
    }
    .approval-menu-divider{
      .vertical-divider-title{
        height: 100%;
        width: 2px;
      }
    }
    .approval-content{
      padding-top: 22px;
      padding-right: 30px;
      font-size: 18px;
      .approval-row{
        padding: 0;
        vertical-align: middle;
        .row-start{
          .approval-type{
            color: #FFAB3B;
            float: left;
          }
          .ant-divider-vertical {
            height: 1.6em;
            margin: 0 8px 8px 8px;
            float: left;
          }
          .approval-num{
            color: #9398A4;
            float: left;
          }
        }
        .row-end{
          .add-btn{
            float: right;
            border-radius: 23px;

          }
        }

      }

      .approval-table{
        .confirmBtn{
          background-color: #FC9B1A;
          color: white;
          border: none;
        }
        .ant-pro-table-list-toolbar-container {
          overflow-x: hidden;
          max-width: 800px;
        }
        .ant-pro-form-light-filter .ant-form-item {
          color:#9398A4;
        }
        .select-input{
          .select-icon{
            .anticon svg {
              width: 20px;
              height: 20px;
            }
          }
          .ant-input-affix-wrapper {
            height: 46px;
            border-radius: 23px;
            font-size: 12px;
          }
          .ant-input-affix-wrapper-focused{
            height: 46px;
            border-radius: 23px;
            font-size: 12px;
            color: #FC9B1A;
          }
          .select-text{
            color: #9398A4;
          }
        }

      }
    }
  }
  .ant-card-body{
    padding: 2px 0 ;
  }
}





@primary-color: #FC9B1A;