.department{
  color: #9398A4;
  font-size: 16px;
  .normal-tabs{
    .ant-tabs-nav {
      width: 100%;
      margin-left: 0px;
    }
  }
  .department-box{
    .department-title{
      text-align: left;
      display: flex;
      align-items: center;
      padding: 30px 60px 6px 30px;
      font-size: 22px;
      color: black;
    }
    .divider-title{
      height: 1px;
      width: 100%;
      color: #BFC1C5;
      margin: 6px 0 0px;
    }
    .department-row{
      .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
        border-right: none;
      }
      .ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin-bottom: 10px;
      }
      .department-menu-col{
        padding: 20px 0 10px 10px;
        .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
          border-right:none;
        }
      }
      .ant-menu-vertical .ant-menu-submenu, .ant-menu-vertical-left .ant-menu-submenu, .ant-menu-vertical-right .ant-menu-submenu, .ant-menu-inline .ant-menu-submenu {
        padding-bottom: 0.02px;
        font-size: 16px;
      }
      .ant-menu-vertical .ant-menu-submenu, .ant-menu-vertical-left .ant-menu-submenu, .ant-menu-vertical-right .ant-menu-submenu, .ant-menu-inline .ant-menu-submenu:first-child {
        padding-bottom: 0.02px;
        font-size: 18px;
      }
      .ant-menu-sub.ant-menu-inline {
        border: 0;
        background: #FFFFFF;
        font-size: 16px;
      }
      .department-menu-divider{
        .vertical-divider-title{
          height: 100%;
          width: 2px;
        }
      }
      .department-menu-right{
        text-align: left;
        .department-menu-right-content{
          margin: 20px 80px 10px 10px;
          .content-title{
            .content-title-name{
              display: flex;
              align-items: center;
              font-size: 20px;
              color: #FFAB3B;
              justify-content: space-between;
              flex-direction: row;
            }
            .content-title-remark{
              margin-left: 30px;
            }
          }
          .content-body{
            margin-top: 20px;
            .ant-tabs-tab {
              padding: 4px 10px;
              font-size: 16px;
            }
            .content-body-number-span{
              font-size: 16px;
              color: #9398A4;
              .content-body-number{
                color: #FFAB3B;
              }
            }
            .project-box{
              .ant-card-body {
                padding: 0;
              }
              .ant-pro-table-list-toolbar-container {
                float: right;
                padding: 0 0 10px;
              }
            }
            .department-customer-search{
              width: 20%;
              display: flex;
              height: 32px;
              margin-left: 65%;
              text-align: center;
              align-items: center;
              .ant-input-prefix {
                margin-right: 10px;
              }
              .ant-input-affix-wrapper {
                box-shadow: 0px 0px 0px #00000029;
                border-radius: 25px;
              }
              a{
                color: #9398A4;
              }
            }
          }
        }
      }
    }
  }
}

.content-model{
  .ant-modal-footer {
    padding: 0;
    text-align: right;
    background: transparent;
    border-top: 0 solid #f0f0f0;
    border-radius: 0 0 2px 2px;
  }

  .content-model-body{
    display: block;
    .content-model-body-form{
      display: block;
      .ant-form-item-explain.ant-form-item-explain-error {
        margin-left: 20px;
      }
      .content-model-body-form-input{
        margin-left: 20px;
      }
      .ant-form-item-label > label {
        font-size: 16px;
        font-weight: bold;
      }
      .content-model-body-form-btn-box{
        margin-top: 3%;
        display: flex;
        justify-content: space-around;
        .content-model-body-form-btn-style{
          border-radius: 25px;
          padding: 0 40px;
        }
      }
    }
  }
}
@primary-color: #FC9B1A;