.set-box{
  margin: 20px auto;
  background-color: white;
  width: 80%;
  border-radius: 10px;
  .set-title{
    padding-top: 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    margin-left: 40px;
  }
  .set-content{
    text-align: center;
    width: 30%;
    margin: 0 auto;
    .set-content-form{
      margin-top: 60px;
      .set-button-box{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .set-button{
          width: 100px;
          border-radius: 25px;
          height: 36px;
          margin-bottom: 20px;
        }
      }
      .ant-form-item-label > label {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .ant-input{
        border-radius: 25px;
        padding-left: 10px;
        height: 32px;
      }
      .ant-select-selector{
        border-radius: 25px;
        // padding-left: 10px;
        height: auto;
        padding: 4px 11px;
        text-align: left;
        border: 1px solid #ccc;
      }
      .ant-select-selection-placeholder,.ant-select-selection-item{
        padding-left:10px
      }
      .ant-input-affix-wrapper{
        border-radius: 25px;
        height: 40px;
      }
    }
  }
}

//手机弹窗
.content-model{
  .content-model-body{
    .ant-steps{
      margin: 0 auto;
      width: 90%;
    }
    .steps-content{
      margin-top: 30px;
      .steps-content-title{
        text-align: center;
        margin-bottom: 20px;
      }
      .content-model-body-form{
        .ant-input{
          border-radius: 25px;
          height: 32px;
          padding-left: 10px;
        }
        .ant-input-affix-wrapper{
          border-radius: 25px;
          height: 40px;
        }
      }
    }
    .steps-action{
      text-align: center;
      margin-top: 30px;
    }
  }
}

@primary-color: #FC9B1A;