.user{
  padding: 30px 50px;

}

.user-tabs{
  .ant-tabs-nav{
    justify-content: center;
    width: auto !important;
    align-items: center;
    margin: auto !important;
    min-width: 0 !important;
    max-width: 100% !important;
    .ant-tabs-tab-btn{
      font-size: 30px;
    }
    .ant-tabs-nav-wrap::after{
      //display: none !important;
    }

  }
}
.task-list-box {
  .ant-list-header{
    text-align: start;
  }
  .ant-list-item-meta-avatar{
    right: 10%;
  }
  .add-box{
    display: flex;
    text-align: center;
    align-items: center;
    align-content: center;
    align-self: center;
    color: #FFAB3B;
  }
  .pop-box{
    margin-right: 5%;
    font-size: 20px;
    //color: #6D7078;
  }
  .down-line-assign{
    margin-left: 10px;
    color: #FFAB3B;
    font-size: 22px;
  }
  .assign-title-time{
    color: #000000;
    margin-left: 20px;
  }
  .assign-title-line{
    color: #9398A4;
    margin-left: 20px;
  }
  .assign-title-num{
    color: #9398A4;
    margin-left: 20px;
    margin-top: 20px;
  }
  .ant-list-item{
    padding: 9px 0;
    border-bottom: 1px solid #f1f2f3;
  }
  .ant-list-item:last-child{
    padding: 9px 0;
    border-bottom: 1px solid #f1f2f3 !important;
  }
  .ant-list-item:hover{
    .task-list-item-box{
      .task-name{
        font-size: 18px;
        //color: #FFB85A;
      }
    }
    cursor:pointer;
  }
  .task-list-item-box{
    width: 100%;
    .task-name-box{
      padding-left: 10px;
      text-align: start;
      .task-name{
        font-size: 18px;
        color: #000000;
      }
      .alarm-icon-style{
        margin-top: 5px;
        font-size: 20px;
        color: #FF4848;
      }
      .task-name-red{
        font-size: 18px;
        color: #FF4848;
      }
      .task-type{
        color: #9398A4;
        font-size: 12px;
        margin-top: 11px;
      }
    }
    .task-link-box{
      display:flex;
      flex-direction:row;
      align-items: center;
      justify-content: center;
      height: 50px;
      .task-link-icon{
        width: 16px;
        height: 16px;
      }
      span{
        font-size: 14px;
        color: #9398a4;
      }
    }
    .task-end-time-alarm{
      color: #FF4848;
      font-size: 14px;
      height: 50px;
      display: flex;
      align-items: center;
    }
    .task-end-time{
      color: #9398a4;
      font-size: 14px;
      height: 50px;
      display: flex;
      align-items: center;
    }
    .task-head-box{
      height: 100%;
      align-items: center;
      .clock-icon {
        display:flex;
        align-items: center;
      }
      .alarm-clock {
        display:flex;
        align-items: center;
      }
    }
  }



  .task-more-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  .task-modal{
    padding: 44px 55px;

  }

  .add-assign-modal{
    .add-assign-title {
      padding-left: 3%;
      display: flex;
      align-items: center;
      .add-assign-title-name {
        font-size: 24px;
        color: #FC9B1A;
        margin-left: 5px;
      }
    }
    .assign-title{
      padding: 3% 10% 0 10%;
      .input-title{
        padding-right: 2%;
        font-size: 30px;
        align-items: center;
        display: flex;
        color: #FF4848;
        .input-title-text{
          font-size: 18px;
          color: #000000;
          width: 100%;
        }
      }
      .input-block{
        .input-box{
          width: 100%;
          padding-left: 10%;
          height: 50px;
          border-radius: 31px;
          font-size: 16px;
        }
      }
    }
    .title-horizontal {
      width: 100%;
      height: 10px;
      .horizontal-divider-modal-title {
        width: 95%;
        height: 1px;
        color: #9398A4;
      }
      .ant-divider-horizontal {
        display: flex;
        clear: both;
        min-width: 10%;
        margin: 20px auto 0px;
      }
    }
    .add-form {
      //padding: 5% 0 2% 0;
     .ant-form-item{
        padding-bottom: 16px;
      }
      .ant-input{
        border: 1px solid #9398A4;
        border-radius: 31px;
        height: 32px;
      }
      .ant-select-selector{
        border: 1px solid #9398A4;
        border-radius: 31px;
        height: 32px;
      }

      .start-button{
        //margin-top: 10px;
        border-radius: 35px;
        float: right;
      }
      .add-customer-button{
        align-items: center;
        text-align: center;
        width: 100%;
        .customer-button-cancel{
          font-size: 18px;
          border-radius: 20px;
          width: 20%;
          height: 36px;
          color: #9398A4;
        }
        .customer-button-ok{
          margin-left: 5%;
          font-size: 18px;
          border-radius: 20px;
          width: 20%;
          height: 36px;
        }
      }
      .update-customer-button{
        align-items: center;
        width: 100%;
        position: absolute;
        bottom: 10px;
        margin: 0 0 0 auto;
        .customer-button-cancel{
          float: right;
          font-size: 14px;
          border-radius: 20px;
          width: 20%;
          height: 32px;
          color: #9398A4;
        }
        .customer-button-ok{
          float: right;
          margin-left: 5%;
          margin-right: 5%;
          font-size: 14px;
          border-radius: 20px;
          width: 20%;
          height: 32px;
        }
      }
      .anticon-plus {
        color: #9398A4;
        //font-size: 20px;
      }
      .department-menu-divider{
        .vertical-divider-title{
          height: 100%;
          width: 2px;
        }
      }
      .detail-area{
        border: 1px solid #9398A4;
        border-radius: 14px;
        height: 200px;
      }

      .ant-form-item-control-input-content{
        width: 100%;
      }
      .testItem-input{
        border: 1px solid #9398A4;
        border-radius: 31px;
        height: 32px;
        .ant-input{
          border:none;
          height: auto;
          padding-left:10px;
        }
        .ant-input-suffix{
          padding: 0 10px;
        }
      }
      .check-box-group{
        width: 100%;
        padding-top: 10px;
        font-size: 20px;
        .check-box-item{
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .ant-btn-icon-only{
            color: red;
          }
        }


      }
      .total-time{
        color: red;
        font-size: 18px;
      }

      [data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
      .site-collapse-custom-collapse{
        .site-collapse-custom-panel {
          margin-bottom: 24px;
          overflow: hidden;
          //background: #f7f7f7;
          border-radius: 4px ;

        }

      }
      .ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only{
        padding: 8px 10px 8px 38px;
      }

      .time-item{
         border-top: #9398A4 1px;
         .time-item-row{
           width: 100%;
           justify-content: space-between;
           align-items: center;
         }
         .item-divider{
           margin: 10px 0;

         }
       }
      .ant-collapse-header .ant-collapse-extra {
        float: right;
        margin-top: -30px;
      }
    }



  }
  .ant-modal-content{
    border-radius: 5px;
    .ant-modal-header {
      padding: 10px 24px;
      color: rgba(0, 0, 0, 0.85);
      background: #fff;
      border-radius: 2px 2px 0 0;
      border-bottom: 0 solid #f0f0f0;

      .ant-modal-title{
        text-align: center;
      }
    }
    .ant-modal-footer {
      padding: 10px 16px;
      text-align: right;
      background: transparent;
      border-top: 0 solid #f0f0f0;
      border-radius: 0 0 2px 2px;
    }
  }


}

.user-document{
  padding: 46px 10px;
  height: 100%;
  .col-divider{
    .divider-title{
      width: 2px;
      height: 100%;
    }
  }
  .document-menu{
    width: 100%;
    padding-left: 10%;
    .menu-item-background {
      font-size: 20px;
      border-radius: 15px;
      .img-box {
        text-align: center;
        display: flex;
      }
      .text-grey{
        color: #9398A4;
      }
      .text-active{
        color: #FC9B1A;
      }
    }
    .horizontal-divider{
      width: 100%;
      height: 2px;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #F0F3F8;
    border-radius: 30px;
    color: #FC9B1A;
  }
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0px solid #f0f0f0;
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 30px;
  }
  .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 53px;
    line-height: 53px;
  }
  .content-right{
    .document-table{
      .ant-pro-table-list-toolbar-container {
        overflow-x: hidden;
        max-width: 800px;
      }
      .select-input{
        .select-icon{
          .anticon svg {
            width: 20px;
            height: 20px;
          }
        }
        .ant-input-affix-wrapper {
          height: 46px;
          border-radius: 23px;
          font-size: 12px;
        }
        .ant-input-affix-wrapper-focused{
          height: 46px;
          border-radius: 23px;
          font-size: 12px;
          color: #FC9B1A;
        }
        .select-text{
          color: #9398A4;
        }
      }

    }
  }
}


.align-center{
    margin: 0 auto;
}
@primary-color: #FC9B1A;